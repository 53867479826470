import './legacy';
import * as RailsUJS from '@rails/ujs';
import * as Turbolinks from 'turbolinks';

// Load Stimulus controllers
import "~/controllers";

// Icons
import 'remixicon/fonts/remixicon.css'

console.log('Vite ⚡️ Rails - Devise')

RailsUJS.start();
Turbolinks.start();